import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"
export default function Testimonials() {
  const { t } = useTranslation()

  return (
    <section
      className="overflow-hidden bg-gray-50 py-12 md:py-20 lg:py-16"
      id="testimonials"
    >
      <div className="flex justify-center mb-10 px-2 flex-1">
        <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
          {t("testimonials_title")}
        </h2>
      </div>
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-full right-full translate-x-1/3 -translate-y-1/4 transform lg:translate-x-1/2 xl:-translate-y-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <title id="svg-workcation">GEERD</title>
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
          />
        </svg>

        <div className="relative">
          <div className="flex justify-center">
            {" "}
            <StaticImage
              className="w-56"
              src="https://res.cloudinary.com/geerd/image/upload/v1671651136/logo_qn3liw.png"
              alt="UM6P-logo"
              placeholder="blurred"
            />
          </div>

          <blockquote className="mt-10">
            <div className="mx-auto max-w-3xl text-center text-lg font-medium leading-9 text-gray-900">
              <p>&ldquo;{t("habti_testimonial")}&rdquo;</p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <img
                    className="mx-auto h-10 w-10 rounded-full"
                    alt="Hicham_Habti"
                    src="https://res.cloudinary.com/geerd/image/upload/v1670590562/corporate/hicham_habti_na1rv3.jpg"
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">
                    Hicham El Habti
                  </div>

                  <svg
                    className="mx-1 hidden h-5 w-5 text-primary-600 md:block"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">
                    President, UM6P
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  )
}
