import React from "react"
import {
  BoltIcon,
  BugAntIcon,
  CalendarDaysIcon,
  PuzzlePieceIcon,
  ChartBarIcon
} from "@heroicons/react/24/outline"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"

const transferFeatures = [
  {
    id: 1,
    name: {
      fr: "Réunion de lancement : Une stratégie de réussite",
      en: "Kick-Off Meeting: Setting the Stage for Success",
    },
    description: {
      fr: "Parlez nous de votre vision, vos expériences passées et vos défis actuels pour comprendre vos besoins spécifiques et élaborer une solution sur mesure qui répond à votre budget et vous permet d'atteindre vos objectifs avec les technologies éducatives.",
      en: "We're here to help you succeed with educational technologies. Tell us about your vision, past experiences, and current challenges. We'll listen carefully to understand your specific needs and develop a tailored solution that fits your budget and helps you reach your goals",
    },

    icon: CalendarDaysIcon,
  },
  {
    id: 2,
    name: {
      fr: "Conception stratégique : Un plan sur mesure",
      en: "Strategic Design: Crafting a Custom Plan with Mock-Ups",
    },
    description: {
      fr: "Nous travaillerons avec vous pour créer un plan sur mesure qui répond à vos besoins à court et à long terme, et fournirons rapidement des prototypes et maquettes pour vous aider à visualiser nos solutions.",
      en: "We'll work with you to create a custom plan that addresses your immediate and long-term needs, and quickly provide prototypes and mock-ups to help you visualize our solution.",
    },
    icon: PuzzlePieceIcon,
  },
  {
    id: 3,
    name: {
      fr: "Ajustement et déploiement : Livraison de la solution",
      en: "The perfect fit: Delivering the Perfect Solution for Your Needs",
    },
    description: {
      fr: "Nous vous apportons des résultats rapides grâce à nos solutions plug-and-go. Notre équipe d'experts crée un MVP en quelques semaines, puis prépare le déploiement et l'intégration dans votre organisation. Faites-nous confiance pour vous fournir les solutions dont vous avez besoin.",
      en: "We will get you rapid results with our plug-and-go solutions. Our team of experts can create a minimum viable product (MVP) in just a few weeks, and ensure it's ready for deployment and integration into your organization. Trust us to provide the solutions you need, when you need them.",
    },
    icon: BoltIcon,
  },
]
const communicationFeatures = [
  {
    id: 1,
    name: {
      fr: "Suivi de l'impact : Mesure et analyse des résultats",
      en: "Measuring Success: Tracking and Analytics",
    },
    description: {
      fr: "Notre équipe est dévouée à votre réussite et vous offrira un soutien et une assistance en continu pour vous aider à suivre et mesurer les résultats de nos solutions, garantissant ainsi un véritable ajout de valeur à votre entreprise.",
      en: "Trust us to be a partner in your digital transformation journey. Our team is dedicated to your success and will provide ongoing support and guidance to help you track and measure the results of our solutions, ensuring they bring real added value to your business.",
    },
    icon: ChartBarIcon,
  },
  {
    id: 2,
    name: {
      fr: "Restez en avance sur la concurrence : Solutions d'amélioration continue",
      en: "Staying Ahead of the Game: Continuous Improvement Solutions",
    },
    description: {
      fr: "Nous disposons d’un équipe dédiée à l'introduction de nouvelles technologies et idées qui anticipent vos besoins en constante évolution pour vous permettre de rester en avance sur la concurrence",
      en: "Our team is dedicated to introducing new technologies and ideas that anticipate your evolving needs to help you stay ahead of the curve.",
    },
    icon: BugAntIcon,
  },
]

export default function Features() {
  const { t } = useTranslation()
  const { language } = useI18next()

  return (
    <div className="overflow-hidden bg-gray-50 py-16 lg:py-24" id="services">
      <div className="relative mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
        <svg
          className="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div className="relative">
          <h2 className="text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            {t("features_title")}
          </h2>
          <p className="mx-auto mt-4 max-w-3xl text-center text-lg text-gray-500">
            {t("features_subtitle")}
          </p>
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="relative">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              {t("featureslefttitle")}
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              {t("featuresleftsubtitle")}
            </p>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map(item => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-xl bg-primary-500 text-white">
                      <item.icon className="h-8 w-8" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                      {item.name[language]}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {item.description[language]}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              />
            </svg>
            <StaticImage
              src="../../images/features_1.jpeg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Feature"
              width={490}
              className="relative mx-auto"
              placeholder="blurred"
            />
          </div>
        </div>

        <svg
          className="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                {t("featuresrighttitle")}
              </h3>
              {/* <p className="mt-3 text-lg text-gray-500">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit
                ex obcaecati natus eligendi delectus, cum deleniti sunt in
                labore nihil quod quibusdam expedita nemo.
              </p> */}

              <dl className="mt-10 space-y-10">
                {communicationFeatures.map(item => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-xl bg-primary-500 text-white">
                        <item.icon className="h-8 w-8" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                        {item.name[language]}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {item.description[language]}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
              <svg
                className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                />
              </svg>
              <StaticImage
                src="../../images/features_2.jpeg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Feature"
                width={490}
                className="relative mx-auto"
                placeholder="blurred"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
