import React from "react"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"

export default function Team() {
  const { t } = useTranslation()
  return (
    <div className="bg-gray-900" id="team">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              <Trans>Meet our team</Trans>
            </h2>
            <p className="text-xl text-gray-300">{t("team_subtitle")}</p>
          </div>
          <ul
            role="list"
            className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8"
          >
            <li className="rounded-lg bg-gray-800 py-10 px-6 text-center xl:px-10 xl:text-left">
              <div className="space-y-6 xl:space-y-10">
                <StaticImage
                  imgClassName="mx-auto h-40 w-40 rounded-full object-cover xl:h-56 xl:w-56"
                  width={400}
                  src="https://res.cloudinary.com/geerd/image/upload/ar_1:1,c_fill,g_auto,w_500/v1672246063/corporate/othmane_pdp_h1bznc.jpg"
                  alt=""
                  placeholder="blurred"
                />
                <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h3 className="text-white">Othmane Sabih</h3>
                    <p className="text-indigo-400">Co-founder, CEO & CTO</p>
                  </div>
                </div>
              </div>
            </li>
            <li className="rounded-lg bg-gray-800 py-10 px-6 text-center xl:px-10 xl:text-left">
              <div className="space-y-6 xl:space-y-10">
                <StaticImage
                  imgClassName="mx-auto h-40 w-40 rounded-full object-cover xl:h-56 xl:w-56"
                  width={400}
                  alt=""
                  src="https://res.cloudinary.com/geerd/image/upload/ar_1:1,c_fill,g_auto,w_500/v1672246059/corporate/yassine_pdp_x306c9.jpg"
                  placeholder="blurred"
                />
                <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h3 className="text-white">Yassine El Bouzkouri</h3>
                    <p className="text-indigo-400">Co-founder & COO</p>
                  </div>
                </div>
              </div>
            </li>
            <li className="rounded-lg bg-gray-800 py-10 px-6 text-center xl:px-10 xl:text-left">
              <div className="space-y-6 xl:space-y-10">
                <StaticImage
                  imgClassName="mx-auto h-40 w-40 rounded-full object-cover xl:h-56 xl:w-56"
                  width={400}
                  src="https://res.cloudinary.com/geerd/image/upload/v1619628656/corporate/1593134268456_zop6d3.jpg"
                  alt=""
                  placeholder="blurred"
                />
                <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h3 className="text-white">Yassine Manane</h3>
                    <p className="text-indigo-400">Advisor, Data & Analytics</p>
                  </div>
                </div>
              </div>
            </li>
            <li className="rounded-lg bg-gray-800 py-10 px-6 text-center xl:px-10 xl:text-left">
              <div className="space-y-6 xl:space-y-10">
                <StaticImage
                  imgClassName="mx-auto h-40 w-40 rounded-full object-cover xl:h-56 xl:w-56"
                  width={400}
                  src="https://res.cloudinary.com/geerd/image/upload/v1650813628/corporate/sara_mekkaoui_fqst1y.jpg"
                  alt=""
                  placeholder="blurred"
                />
                <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h3 className="text-white">Sara Mekkaoui</h3>
                    <p className="text-indigo-400">Senior Financial Advisor (Fellow)</p>
                  </div>
                </div>
              </div>
            </li>
            <li className="rounded-lg bg-gray-800 py-10 px-6 text-center xl:px-10 xl:text-left">
              <div className="space-y-6 xl:space-y-10">
                <StaticImage
                  imgClassName="mx-auto h-40 w-40 rounded-full object-cover xl:h-56 xl:w-56"
                  width={400}
                  src="https://res.cloudinary.com/geerd/image/upload/v1619628751/corporate/1517013770836_yw0baj.jpg"
                  alt=""
                  placeholder="blurred"
                />
                <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h3 className="text-white">Mohamed Nachit</h3>
                    <p className="text-indigo-400">Senior Backend Developer</p>
                  </div>
                </div>
              </div>
            </li>
            <li className="rounded-lg bg-gray-800 py-10 px-6 text-center xl:px-10 xl:text-left">
              <div className="space-y-6 xl:space-y-10">
                <StaticImage
                  imgClassName="mx-auto h-40 w-40 rounded-full object-cover xl:h-56 xl:w-56"
                  width={400}
                  src="https://res.cloudinary.com/geerd/image/upload/v1650301414/corporate/1634697052839_kjhpcq.jpg"
                  alt=""
                  placeholder="blurred"
                />
                <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h3 className="text-white">Oussama Filani</h3>
                    <p className="text-indigo-400">Full Stack Developer</p>
                  </div>
                </div>
              </div>
            </li>
            <li className="rounded-lg bg-gray-800 py-10 px-6 text-center xl:px-10 xl:text-left">
              <div className="space-y-6 xl:space-y-10">
                <StaticImage
                  imgClassName="mx-auto h-40 w-40 rounded-full object-cover xl:h-56 xl:w-56"
                  width={400}
                  src="https://res.cloudinary.com/geerd/image/upload/v1650997108/corporate/othmane_kahtal_f4pzph.jpg"
                  alt=""
                  placeholder="blurred"
                />
                <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h3 className="text-white">Othmane Kahtal</h3>
                    <p className="text-indigo-400">Full Stack Developer</p>
                  </div>
                </div>
              </div>
            </li>
            <li className="rounded-lg bg-gray-800 py-10 px-6 text-center xl:px-10 xl:text-left">
              <div className="space-y-6 xl:space-y-10">
                <StaticImage
                  imgClassName="mx-auto h-40 w-40 rounded-full object-cover xl:h-56 xl:w-56"
                  width={400}
                  src="https://res.cloudinary.com/geerd/image/upload/c_scale,w_800/v1679415069/corporate/rime_elkhatab_tnmdsu.jpg"
                  alt=""
                  placeholder="blurred"
                />
                <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h3 className="text-white">Rime El Khatab</h3>
                    <p className="text-indigo-400">Marketing and Social Media Manager</p>
                  </div>
                </div>
              </div>
            </li>
            <li className="rounded-lg bg-gray-800 py-10 px-6 text-center xl:px-10 xl:text-left">
              <div className="space-y-6 xl:space-y-10">
                <StaticImage
                  imgClassName="mx-auto h-40 w-40 rounded-full object-cover xl:h-56 xl:w-56"
                  width={400}
                  src="https://res.cloudinary.com/geerd/image/upload/v1652015820/corporate/brahim_mellouk_hmhfvj.jpg"
                  alt=""
                  placeholder="blurred"
                />
                <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h3 className="text-white">Brahim Mellouk</h3>
                    <p className="text-indigo-400">Graphic Designer</p>
                  </div>
                </div>
              </div>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  )
}
