import React from "react"

import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { graphql, Link, useStaticQuery } from "gatsby"

export default function Careers() {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query allContentfulJobOffer {
      allContentfulJobOffer(sort: { fields: [publishDate], order: DESC }) {
        edges {
          node {
            id
            title
            slug
            publishDate(formatString: "MMMM Do, YYYY")
            location
            body {
              childMarkdownRemark {
                excerpt
              }
            }
          }
        }
      }
    }
  `)

  const posts = data.allContentfulJobOffer.edges
  return (
    <div
      id="careers"
      className="bg-white px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28 border border-t"
    >
      <div className="relative mx-auto max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            <Trans>Careers</Trans>
          </h2>
          <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:items-center lg:gap-5">
            <p className="text-xl text-gray-500">
              {t("careers_subtitle")}
            </p>
            {/* <form
              id="careers-newsletter-form"
              className="mt-6 flex flex-col sm:flex-row lg:mt-0 lg:justify-end"
            >
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email-address"
                  type="email"
                  autoComplete="email"
                  required
                  className="w-full appearance-none rounded-md border border-gray-300 bg-white px-4 py-2 text-base text-gray-900 placeholder-gray-500 focus:border-primary-500 focus:outline-none focus:ring-primary-500 lg:max-w-xs"
                  placeholder="Enter your email"
                />
              </div>
              <div className="mt-2 flex w-full flex-shrink-0 rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:inline-flex sm:w-auto">
                <button
                  type="button"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:inline-flex sm:w-auto"
                >
                  Notify me
                </button>
              </div>
            </form> */}
          </div>
        </div>
        <div className="mt-6 grid gap-16 pt-10 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
          {posts.map(post => (
            <div key={post.node.title}>
              <p className="text-sm text-gray-500">
                <time dateTime={post.datetime}>{post.node.publishDate}</time>
              </p>
              <Link to={`/careers/${post.node.slug}`} className="mt-2 block">
                <p className="text-xl font-semibold text-gray-900">
                  {post.node.title}
                </p>
                <p className="mt-3 text-base text-gray-500">
                  {post.node.body.childMarkdownRemark.excerpt}
                </p>
              </Link>
              <div className="mt-3">
                <Link
                  to={`/careers/${post.node.slug}`}
                  className="text-base font-semibold text-primary-600 hover:text-primary-500"
                >
                  <Trans>Read more</Trans>...
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
