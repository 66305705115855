import React from "react"

import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../sections/hero"
import Founders from "../sections/founders"
import Team from "../sections/team"
import BlogSection from "../sections/blog"
import Partners from "../sections/partners"
import Features from "../sections/features"
import Metrics from "../sections/metrics"
import Testimonials from "../sections/testimonials"
import Careers from "../sections/careers"

export default function Index({ data }) {
  const { t } = useTranslation()
  const { language } = useI18next()

  const posts = data.allContentfulBlogPost.edges
  console.log(data)
  return (
    <Layout>
      {/* Hero section */}
      <Seo
        title={t("meta_title")}
        lang={language}
        description={t("meta_description")}
      />
      <Hero />
      <Partners />
      <Founders />
      <Features />
      <Team />
      {/* Gradient Feature Section */}
      {/* <div className="bg-gradient-to-r from-secondary-800 to-primary-700 relative">
        <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white tracking-tight">
            {t("features_title")}
          </h2>
          <p className="mt-4 max-w-3xl text-lg text-secondary-200">
            {t("features_subtitle")}
          </p>
          <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
            {features.map(feature => (
              <div key={feature.name[language]}>
                <div>
                  <span className="flex items-center justify-center h-16 w-16 rounded-md bg-white bg-opacity-10">
                    <feature.icon
                      className="h-12 w-12 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h3 className="text-lg font-medium text-white">
                    {feature.name[language]}
                  </h3>
                  <p className="mt-2 text-base text-secondary-200">
                    {feature.description[language]}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      {/* Logo Cloud */}
      {/* <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
            {t("partners")}
          </p>
          <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-3 items-center">
            <div className="col-span-1 flex justify-center">
              <OCP />
            </div>
            <div className="col-span-1 flex justify-center">
              <ABS />
            </div>
            <div className="col-span-1 flex justify-center">
              <UM6P />
            </div>
          </div>
        </div>
      </div> */}

      {/* Alternating Feature Sections */}

      {/* <Faq /> */}
      <Testimonials />
      <BlogSection posts={posts} />
      {/* <Pricing /> */}
      <Metrics />
      <Careers />

      {/* CTA section */}
      <div className="bg-gradient-to-b from-primary-50 via-white to-white">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-24 lg:px-8">
          <h2 className="text-2xl font-bold tracking-tight text-primary-900 sm:text-3xl">
            <span className="block">{t("footer_title")}</span>
            <span className="block text-primary-600">
              {t("footer_message")}
            </span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="contact"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 py-3 px-5 text-base font-medium text-white hover:bg-primary-700"
              >
                <Trans>Commencer</Trans>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      {/* <div className="bg-gradient-to-r from-secondary-200 to-primary-300">
        <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">
              <Trans>Vous êtes à la recherche d'une offre personnalisée</Trans>
            </span>
            <span className="block bg-gradient-to-r from-secondary-600 to-primary-700 bg-clip-text text-transparent">
              <Trans>répondant à vos besoins très spécifiques</Trans> ?
            </span>
          </h2>
          <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
            <Link
              to="contact"
              className="flex items-center justify-center bg-gradient-to-r from-secondary-600 to-primary-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-secondary-700 hover:to-primary-700"
            >
              <Trans>Contactez-nous</Trans>
            </Link>
          </div>
        </div>
      </div> */}
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          author {
            name
            image {
              gatsbyImageData(
                width: 50
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
  
`
