import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"


export default function Metrics() {
  const { t } = useTranslation()

  return (
    <div className="relative bg-white" id="stats">
      <div className="h-56 bg-primary-600 sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-1/2">
        <StaticImage
          className="h-full w-full object-cover"
          src="https://res.cloudinary.com/geerd/image/upload/c_scale,w_700/v1671821717/corporate/photo-1522071820081-009f0129c71c_rheco8.jpg"
          alt="Support team"
          quality={100}
          placeholder="blurred"
        />
      </div>
      <div className="relative mx-auto max-w-7xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16">
        <div className="mx-auto max-w-2xl lg:mr-0 lg:ml-auto lg:w-1/2 lg:max-w-none lg:pl-10">
          {/* <div>
            <div className="flex h-12 w-12 items-center justify-center rounded-md bg-primary-500 text-white">
              <UsersIcon className="h-6 w-6" aria-hidden="true" />
            </div>
          </div> */}
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {t("stats_title")}
          </h2>
          <p className="mt-6 text-lg text-gray-500">
          {t("stats_paragraph")}

          </p>
          <div className="mt-8 overflow-hidden">
            <dl className="-mx-8 -mt-8 flex flex-wrap">
              <div className="flex flex-col px-8 pt-8">
                <dt className="order-2 text-base font-medium text-gray-500"><Trans>Average lead time for MVP</Trans></dt>
                <dd className="order-1 text-2xl font-bold text-primary-600 sm:text-3xl sm:tracking-tight">3 <Trans>weeks</Trans></dd>
              </div>
              <div className="flex flex-col px-8 pt-8">
                <dt className="order-2 text-base font-medium text-gray-500">Satisfaction</dt>
                <dd className="order-1 text-2xl font-bold text-primary-600 sm:text-3xl sm:tracking-tight">96%</dd>
              </div>
              <div className="flex flex-col px-8 pt-8">
                <dt className="order-2 text-base font-medium text-gray-500"><Trans>Users</Trans></dt>
                <dd className="order-1 text-2xl font-bold text-primary-600 sm:text-3xl sm:tracking-tight">10k+</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
