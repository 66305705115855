import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"

export default function Partners() {
  const { t } = useTranslation()

  return (
    <div className="bg-primary-200 bg-opacity-25">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="flex flex-col space-y-8">
          <h2 className="mx-auto max-w-md text-center text-3xl font-bold tracking-tight text-primary-900 lg:max-w-xl lg:text-left">
            {t("partners")}
          </h2>
          <div className="mx-auto flow-root self-center lg:mt-0">
            <div className="-mt-4 -ml-8 flex flex-wrap lg:-ml-4">
              <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                <StaticImage
                  className="w-36"
                  src="https://res.cloudinary.com/geerd/image/upload/v1698954029/customer_logos/logo_20_4__1_xaek8o.png"
                  alt="UM6P Logo"
                  objectFit="contain"
                  width={500}
                  placeholder="blurred"
                />
              </div>
              <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                <StaticImage
                  className="w-24"
                  src="https://res.cloudinary.com/geerd/image/upload/v1671812002/corporate/emines_20copie_firijg.png"
                  alt="EMINES Logo"
                  objectFit="contain"
                  width={500}
                  placeholder="blurred"
                />
              </div>
              <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                <StaticImage
                  className="w-24"
                  src="https://res.cloudinary.com/geerd/image/upload/c_scale,w_500/v1671653184/corporate/LOGO_ABS_Brown_syfphx.png"
                  alt="ABS Logo"
                  objectFit="contain"
                  width={500}
                  placeholder="blurred"
                />
                
              </div>
              <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                <StaticImage
                  className="w-36"
                  src="https://res.cloudinary.com/geerd/image/upload/v1674657388/zonebleue/zonebleue_logo_y052yi.png"
                  alt="ZoneBleue DDB Logo"
                  objectFit="contain"
                  width={500}
                  placeholder="blurred"
                />
                
              </div>
              <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                <StaticImage
                  className="w-36"
                  src="https://res.cloudinary.com/geerd/image/upload/v1679233714/easytest/logo_una_blue_pihmlq.png"
                  alt="UNA Logo"
                  objectFit="contain"
                  width={500}
                  placeholder="blurred"
                />
                
              </div>
              <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                <StaticImage
                  className="w-16"
                  src="https://res.cloudinary.com/geerd/image/upload/v1691243294/customer_logos/logo-color_kjwljq.png"
                  alt="UIR Logo"
                  objectFit="contain"
                  width={500}
                  placeholder="blurred"
                />
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
