/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import Postcard from "./postcard"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"


export default function BlogSection({ posts }) {
  const {t} = useTranslation()

  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8" id="blog">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
          {t("blog_title")}
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
          {t("blog_subtitle")}
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map(post => (
            <Postcard key={post.title} post={post} />
          ))}
        </div>
      </div>
    </div>
  )
}
